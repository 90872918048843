<template>
  <div>
    <h2 class="mb-4">Список пользователей</h2>

    <user-filters class="mb-5" :table-params="tableParams" @change-field="changeField" />

    <user-export class="mb-5" :adapted-params="adaptedParams" />

    <data-table ref="dataTable" url="users" :headers="headers" :table-params="adaptedParams">
      <template v-slot:[`item.email`]="{ item: user }">
        <router-link :to="{ name: 'users-id', params: { id: user.id } }">
          {{ user.email }}
        </router-link>
      </template>

      <template v-slot:[`item.isDeleted`]="{ item: user }">
        <user-status :deleted="user.isDeleted" />
      </template>

      <template v-slot:[`item.role`]="{ item: user }">
        {{ formatRole(user) }}
      </template>

      <template v-slot:[`item.buyerStatus`]="{ item: user }">
        <nobr>{{ formatBuyerStatus(user) }}</nobr>
      </template>

      <template v-slot:[`item.subscription.createdAt`]="{ item: user }">
        <nobr>{{ user.subscription.createdAt | date }} </nobr>
      </template>

      <template v-slot:[`item.subscription.product`]="{ item: user }">
        <nobr>{{ formatTariff(user.subscription.product) }} </nobr>
      </template>

      <template v-slot:[`item.subscription.expiredAt`]="{ item: user }">
        <nobr>{{ user.subscription.expiredAt | date }} </nobr>
      </template>

      <template v-slot:[`item.totalPayments`]="{ item: user }">
        <nobr>{{ user.totalPayments | number }} ₽</nobr>
      </template>

      <template v-slot:[`item.invite`]="{ item: user }">
        <nobr v-if="user.invite" color="success">{{ user.invite.id }}</nobr>
      </template>

      <template v-slot:[`item.createdAt`]="{ item: user }">
        <nobr>{{ user.createdAt | date }}</nobr>
      </template>

      <template v-slot:[`item.actions`]="{ item: user }">
        <nobr>
          <v-btn class="mr-2" icon color="accent" :to="{ name: 'users-id', params: { id: user.id } }">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn v-if="!user.isDeleted" icon color="error" @click="deleteUser(user)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </nobr>
      </template>
    </data-table>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { UserRole } from "~/libs/user/user-role";
import { userStatusEnum } from "~/libs/user/user-status";
import { UserBuyerStatus } from "~/libs/user/user-buyer-status";

import { stringToMultipleFilter } from "~/core/helpers";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import UserFilters from "~/components/users/index/filters";
import UserExport from "~/components/users/index/export";
import UserStatus from "~/components/users/user-status";

export default {
  mixins: [editorForm],
  data() {
    return {
      userStatusEnum,
      headers: [
        { text: "email", value: "email" },
        { text: "Статус", value: "isDeleted" },
        { text: "Роль", value: "role" },
        { text: "Статус подписки", value: "buyerStatus", sortable: false },
        { text: "Дата оформления подписки", value: "subscription.createdAt", sortable: true },
        { text: "Тариф", value: "subscription.product", sortable: true },
        { text: "Дата окончания подписки", value: "subscription.expiredAt", sortable: true },
        { text: "LTV", value: "totalPayments", sortable: true },
        { text: "ID биржи", value: "invite", sortable: false },
        { text: "Дата регистрации", value: "createdAt" },
        { text: "Действия", value: "actions", sortable: false }
      ],

      tableParams: {
        ids: "",
        similarEmails: "",
        roles: [],
        isDeleted: null,
        buyerStatuses: [],
        subscriptionProductId: [],
        subscriptionCreatedAt: { min: null, max: null },
        subscriptionExpiredAt: { min: null, max: null },
        createdAt: { min: null, max: null },
        sites: []
      }
    };
  },
  computed: {
    adaptedParams() {
      return {
        ...this.tableParams,
        ids: stringToMultipleFilter(this.tableParams.ids),
        similarEmails: stringToMultipleFilter(this.tableParams.similarEmails)
      };
    }
  },
  methods: {
    formatRole({ role }) {
      return new UserRole(role).text;
    },
    formatBuyerStatus({ buyerStatus }) {
      return new UserBuyerStatus(buyerStatus).text;
    },
    formatTariff(productId) {
      return this.$store.getters["dictionary/productById"](productId).title;
    },

    async deleteUser({ id }) {
      if (!confirm("Внимание! Удаление пользователя необратимо, продолжить?")) return;

      try {
        await this.$axios.$delete(`users/${id}`);
        await this.$refs.dataTable.update();

        this.$snackbar.success("Пользователь удален");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалось удалить пользователя:" + html);
      }
    },

    changeField(payload) {
      this.commitChange(this.tableParams, payload);
    }
  },
  components: {
    UserStatus,
    UserFilters,
    UserExport
  }
};
</script>
