export const userStatusEnum = {
  active: { uid: false, color: "info", text: "Активен" },
  deleted: { uid: true, color: "error", text: "Удален" }
};

const unknownStatus = {
  uid: -1,
  color: "grey",
  text: "—"
};

export class UserStatus {
  constructor(uid) {
    const status = getUserStatusMap().find(userStatus => userStatus.uid === uid) ?? unknownStatus;

    this.uid = status.uid;
    this.color = status.color;
    this.text = status.text;
  }
}

export function getUserStatusMap() {
  return Object.values(userStatusEnum).map(status => ({ ...status }));
}
