<template>
  <v-chip :color="bage.color">
    {{ bage.text }}
  </v-chip>
</template>

<script>
import { UserStatus } from "~/libs/user/user-status";

export default {
  props: {
    deleted: {
      type: Boolean,
      required: false,
      default: null
    }
  },
  computed: {
    bage() {
      return new UserStatus(this.deleted);
    }
  }
};
</script>
