<template>
  <v-btn color="primary" :loading="loading" @click="exportUsers">Экспорт</v-btn>
</template>

<script>
import FileSaver from "file-saver";

import { ApiValidationError } from "~/core/api-validation-error";
import { sleep } from "~/core/helpers";

export default {
  props: {
    adaptedParams: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async exportUsers() {
      this.loading = true;

      try {
        const { location } = await this.$axios.$post("users/export", { params: this.adaptedParams });

        const file = await this.waitingFile(location);

        FileSaver.saveAs(file.content, file.name);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Ошибка:" + html);
      } finally {
        this.loading = false;
      }
    },
    async waitingFile(location) {
      let file;

      while (!file) {
        const response = await this.$axios.get(location, {
          responseType: "arraybuffer"
        });

        if (response.status === 200) {
          file = this.createFile(response);
        }

        await sleep(500);
      }

      return file;
    },
    createFile(response) {
      const name = response.headers["content-disposition"].split("=")[1].replace(/["]/g, "");

      const content = new Blob([response.data], {
        type: response.headers["content-type"]
      });

      return { name, content };
    }
  }
};
</script>
