export const userRoleEnum = {
  admin: { uid: "admin", text: "Администратор" },
  manager: { uid: "manager", text: "Менеджер" },
  observer: { uid: "observer", text: "Наблюдатель" },
  client: { uid: "client", text: "Клиент" }
};

const unknownRole = {
  uid: "unknown",
  text: "—"
};

export class UserRole {
  constructor(uid) {
    const role = userRoleEnum[uid] ?? unknownRole;

    this.uid = role.uid;
    this.text = role.text;
  }
}

export function getUserRoleMap() {
  return Object.values(userRoleEnum).map(role => ({ ...role }));
}
