export const userSourceEnum = {
  ru: { uid: "ru", text: "BestStocks" },
  kz: { uid: "kz", text: "its-ideas" }
};

const unknownSource = {
  uid: "unknown",
  text: "—"
};

export class UserSource {
  constructor(uid) {
    const site = userSourceEnum[uid] ?? unknownSource;

    this.uid = site.uid;
    this.text = site.text;
  }
}

export function getUserSourceMap() {
  return Object.values(userSourceEnum).map(site => ({ ...site }));
}
