export const editorForm = {
  methods: {
    changeField({ field, value }) {
      console.warn(`[editor-form] Изменено значение поля "${field}", но не указан обработчик. Новое значение: `, value);
    },

    /** Установка значения объекта для любой вложенности */
    commitChange(dest, { field, value }) {
      const fieldPaths = field.split(".");
      const pathDeep = fieldPaths.length;

      if (!pathDeep) {
        console.warn(`[editor-form] Не передано поле field в commitChange`);
      }

      for (let i = 0; i < pathDeep - 1; i++) {
        dest = dest[fieldPaths[i]];
      }

      this.$set(dest, fieldPaths[pathDeep - 1], value);
    }
  }
};
