export const userBuyerStatusEnum = {
  client: { uid: "client", text: "Активна" },
  promo: { uid: "promo", text: "Промо" },
  user: { uid: "user", text: "Отсутствует" },
  exClient: { uid: "exClient", text: "Истекла" },
  deleted: { uid: "deleted", text: "Удалена" }
};

const unknownBuyerStatus = {
  uid: "unknown",
  text: "—"
};

export class UserBuyerStatus {
  constructor(uid) {
    const role = userBuyerStatusEnum[uid] ?? unknownBuyerStatus;

    this.uid = role.uid;
    this.text = role.text;
  }
}

export function getUserBuyerStatusMap() {
  return Object.values(userBuyerStatusEnum).map(status => ({ ...status }));
}
