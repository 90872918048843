<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field label="ID пользователя" :value="tableParams.ids" clearable @input="setField('ids', $event)" />
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          label="Email"
          :value="tableParams.similarEmails"
          clearable
          @input="setField('similarEmails', $event)"
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          :value="tableParams.isDeleted"
          :items="userStatuses"
          label="Статус"
          item-value="uid"
          item-text="text"
          clearable
          @input="setField('isDeleted', $event)"
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          :value="tableParams.roles"
          :items="userRoles"
          label="Роль"
          item-value="uid"
          item-text="text"
          multiple
          @input="setField('roles', $event)"
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          :value="tableParams.buyerStatuses"
          :items="userBuyerStatuses"
          label="Статус подписки"
          item-value="uid"
          item-text="text"
          multiple
          @input="setField('buyerStatuses', $event)"
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          :value="tableParams.subscriptionProductId"
          :items="products"
          label="Тариф"
          item-value="id"
          item-text="title"
          multiple
          @input="setField('subscriptionProductId', $event)"
        />
      </v-col>

      <v-col cols="12" md="3">
        <date-range-picker
          :value="tableParams.subscriptionCreatedAt"
          label="Дата оформления подписки"
          @input="setField('subscriptionCreatedAt', $event)"
        />
      </v-col>

      <v-col cols="12" md="3">
        <date-range-picker
          :value="tableParams.subscriptionExpiredAt"
          label="Дата окончания подписки"
          @input="setField('subscriptionExpiredAt', $event)"
        />
      </v-col>

      <v-col cols="12" md="3">
        <date-range-picker
          :value="tableParams.createdAt"
          label="Дата регистрации"
          @input="setField('createdAt', $event)"
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          v-model="selectedSource"
          :value="tableParams.sites"
          :items="userSource"
          label="Источник"
          item-value="uid"
          item-text="text"
          multiple
          clearable
          @input="setField('sites', $event)"
        >
          <template v-slot:prepend-item>
            <v-list-item @click="toggle">
              <v-checkbox
                :color="isSelectedSource ? 'indigo-darken-4' : undefined"
                :indeterminate="isSelectedSource && !isSelectedAllSource"
                :value="isSelectedSource"
                label="Все"
              />
            </v-list-item>

            <v-divider class="mt-2" />
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { getUserRoleMap } from "~/libs/user/user-role";
import { getUserStatusMap } from "~/libs/user/user-status";
import { getUserBuyerStatusMap } from "~/libs/user/user-buyer-status";
import { getUserSourceMap } from "~/libs/user/user-source";

import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userRoles: getUserRoleMap(),
      userStatuses: getUserStatusMap(),
      userBuyerStatuses: getUserBuyerStatusMap(),
      userSource: getUserSourceMap(),
      selectedSource: []
    };
  },
  computed: {
    products() {
      return this.$store.state.dictionary.products;
    },
    isSelectedSource() {
      return this.selectedSource.length > 0;
    },
    isSelectedAllSource() {
      return this.selectedSource.length === this.userSource.length;
    }
  },
  methods: {
    toggle() {
      if (this.isSelectedAllSource) {
        this.selectedSource = [];
      } else {
        this.selectedSource = this.userSource.slice();
      }

      const soureces = this.selectedSource.map(site => site.uid);

      this.setField("sites", soureces);
    }
  }
};
</script>
